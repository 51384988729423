import { useEffect, useState } from 'react';
import { Box } from '@packages/shared';
import { useSearchParams } from 'next/navigation';
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import type { NewsletterSubscribeFormType, NewsletterSubscriptionFormType } from './types';
import { Page1Form } from './Page1Form';
import { Page2Doi } from './Page2Doi';
import { Page3Success } from './Page3Success';
import { Page4KnownUser } from './Page4KnownUser';

/**
 * The Newsletter subscribe form.
 *
 * It guides the user through the subscription process: From the form to enter the data
 * to the Double Opt In Page, Success Page and even information about
 * an already existing subscription.
 */
export const NewsletterSubscribeForm = ({
  data,
  preselectedPage = 1,
}: {
  data: NewsletterSubscribeFormType | NewsletterSubscriptionFormType;
  preselectedPage?: Number;
}) => {
  const query = Object.fromEntries(useSearchParams()?.entries() ?? []);

  // if ?page=XY is set in URL, use this as initial Page.
  // otherwise preselectedPage (default: 1)
  const [page, setPage] = useState(query && query.page ? Number(query.page) : preselectedPage);

  const areaKey = query.areaKey || data.field_area_key;

  // needed for SSG, because useRouter works only clientside and query is initially undefined
  useEffect(() => {
    if (query && query.page) {
      setPage(Number(query.page));
    }
  }, [query]);
  const {
    field_headline_confirm: confirmHeadline = undefined,
    field_headline_doi: doiHeadline = undefined,
    field_headline_loggedin: loggedinHeadline = undefined,
    field_headline_subscribe: subscribeHeadline = undefined,
    field_text_confirm: confirmText = undefined,
    field_text_doi: doiText = undefined,
    field_text_loggedin: loggedinText = undefined,
    field_text_subscribe: subscribeText = undefined,
    content_snippet_bundled: contentSnippet = undefined,
    field_button_label: buttonLabel = undefined,
    field_button_label_mobile: buttonLabelMobile = undefined,
  } = data.type === 'newsletter_subscription' ? data : {};

  const [{ label: conditionsLabel = undefined, field_conditions: conditionsText = undefined }] =
    contentSnippet && contentSnippet?.length > 0 ? contentSnippet : [{}];

  let pageComponent: EmotionJSX.Element;
  switch (page) {
    case 2:
      pageComponent = (
        <Page2Doi
          headline={doiHeadline}
          text={doiText}
          buttonLabel={buttonLabel}
          buttonLabelMobile={buttonLabelMobile}
        />
      );
      break;
    case 3:
      pageComponent = (
        <Page3Success
          headline={confirmHeadline}
          text={confirmText}
          buttonLabel={buttonLabel}
          buttonLabelMobile={buttonLabelMobile}
          areaKey={areaKey}
        />
      );
      break;
    case 4:
      pageComponent = (
        <Page4KnownUser
          headline={loggedinHeadline}
          text={loggedinText}
          buttonLabel={buttonLabel}
          buttonLabelMobile={buttonLabelMobile}
        />
      );
      break;
    default:
      pageComponent = (
        <Page1Form
          areaKey={areaKey}
          setPage={setPage}
          headline={subscribeHeadline}
          text={subscribeText}
          conditionsLabel={conditionsLabel}
          conditionsText={conditionsText}
          buttonLabel={buttonLabel}
          buttonLabelMobile={buttonLabelMobile}
        />
      );
  }
  return <Box>{pageComponent}</Box>;
};
