import { defineMessages } from 'react-intl';

export const subscribeMessages = defineMessages({
  confirmation: {
    id: 'forms.newsletter.subscribe.confirmation',
    defaultMessage:
      'Ich möchte in Zukunft mit dem kostenlosen Newsletter über alle Angebote und Aktionen informiert werden. Ich weiß, dass ich den Newsletter jederzeit wieder <a>hier abbestellen</a> kann.',
  },
  submit: {
    id: 'forms.newsletter.subscribe.submit',
    defaultMessage: 'Anmelden & 15% Rabatt erhalten',
  },
  submitDisabled: {
    id: 'forms.newsletter.subscribe.submit.disabled',
    defaultMessage: 'Bitte trage deine E-Mail Adresse ein',
  },
});

export const voucherModalMessages = defineMessages({
  linkText: {
    id: 'forms.newsletter.subscribe.voucherModal.linktext',
    defaultMessage: 'Gutscheinbedingungen',
  },
  title: {
    id: 'forms.newsletter.subscribe.voucherModal.title',
    defaultMessage: 'Gutscheinbedingungen',
  },
  content: {
    id: 'forms.newsletter.subscribe.voucherModal.content',
    defaultMessage:
      '<h5>Welche Bedingungen gelten für den Rabatt?</h5>Mindesteinkaufswert nach Retoure: 50,-€, ausgenommen sind Artikel der Marken: Caso, Smeg, KitchenAid, Liebherr und dyson. Diese Aktion ist nicht kombinierbar mit anderen Vorteilen. Keine Barauszahlung oder nachträgliche Verrechnung möglich. Hast du den Vorteil in den vergangenen 6 Monaten bereits einmal genutzt, können wir dir diesen leider nicht erneut gewähren.<h5>Wie löse ich den Rabatt ein?</h5>Nachdem du die Pflichtfelder ausgefüllt und das Formular zur Anmeldung abgeschickt hast, senden wir dir eine E-Mail an die angegebenen E-Mail Adresse.<br />Diese beinhaltet einen Bestätigungslink oder -Button. Durch Anklicken dieses Buttons bestätigst du, dass du einverstanden bis von uns Newsletter zu erhalten.<br />Nach deiner ordnungsgemäßen Registrierung, schicken wir dir eine weitere E-Mail mit einem Link, der per Klick den Rabatt in Höhe von 10% einmalig auf die Sortimente Mode, Schuhe, Wohnen gewährt. Der Rabatt wird automatisch in deinen Warenkorb gelegt und gilt nur im Rahmen einer Online-Bestellung.',
  },
});

export const privacyInfoMessages = defineMessages({
  link: {
    id: 'forms.newsletter.subscribe.privacyInfo.link',
    defaultMessage: '/service-hilfe/ueber-uns/datenschutz/datenschutzhinweis',
  },
  text: {
    id: 'forms.newsletter.subscribe.privacyInfo.text',
    defaultMessage: 'Hinweis zum Datenschutz',
  },
});

export const pagesMessages = {
  page1: defineMessages({
    headline: {
      id: 'forms.newsletter.subscribe.page1.headline',
      defaultMessage: '15% Rabatt für deine Newsletter Anmeldung',
    },
    description: {
      id: 'forms.newsletter.subscribe.page1.description',
      defaultMessage:
        'Dich erwarten: Tipps und Empfehlungen, jede Menge Trends und Inspiration sowie tolle Aktionen & Rabatte!',
    },
  }),

  page2: defineMessages({
    headline: {
      id: 'forms.newsletter.subscribe.page2.headline',
      defaultMessage: 'Fast geschafft …',
    },
    description: {
      id: 'forms.newsletter.subscribe.page2.description',
      defaultMessage:
        'Wir haben dir gerade eine E-Mail geschickt. Bitte bestätige uns deine Anmeldung über den Button in dieser E-Mail. Deine 15% Rabatt senden wir dir danach in einer seperaten E-Mail zu.',
    },
    button: {
      id: 'forms.newsletter.subscribe.page2.button',
      defaultMessage: 'Im Shop stöbern',
    },
    subText: {
      id: 'forms.newsletter.subscribe.page2.subText',
      defaultMessage:
        'Keine E-Mail erhalten? Bitte überprüfe auch deinen Spamordner und hab etwas Geduld – es kann einige Minuten dauern, bis die E-Mail bei dir ankommt.',
    },
  }),

  page3: defineMessages({
    headline: {
      id: 'forms.newsletter.subscribe.page3.headline',
      defaultMessage: 'Wie schön, du bist dabei!',
    },
    description: {
      id: 'forms.newsletter.subscribe.page3.description',
      defaultMessage:
        'Das hat geklappt – du hast dich erfolgreich für unseren Newsletter angemeldet. Deine 15% Rabatt warten in deinem Postfach auf dich.',
    },
    button: {
      id: 'forms.newsletter.subscribe.page3.button',
      defaultMessage: 'Weiter shoppen',
    },
    subText: {
      id: 'forms.newsletter.subscribe.page3.subText',
      defaultMessage:
        'Keine E-Mail erhalten? Bitte überprüfe auch deinen Spamordner und hab etwas Geduld – es kann einige Minuten dauern, bis die E-Mail mit dem Rabattlink bei dir ankommt.',
    },
  }),

  page4: defineMessages({
    headline: {
      id: 'forms.newsletter.subscribe.page4.headline',
      defaultMessage: 'Psst… Wir kennen uns doch. Schön, dass du dabei bist!',
    },
    description: {
      id: 'forms.newsletter.subscribe.page4.description',
      defaultMessage:
        'Mit dieser E-Mail Adresse hast du dich schon für unseren Newsletter angemeldet. Du musst nichts weiter tun.',
    },
    button: {
      id: 'forms.newsletter.subscribe.page4.button',
      defaultMessage: 'Weiter shoppen',
    },
  }),
};
