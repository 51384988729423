import config from '@packages/config';
import { hashData } from '../hashData';

/**
 * Tracks user activity with Dynamic Yield.
 *
 * @param {string} dyType - The type of Dynamic Yield event.
 * @param {string} dySession - Current Session of Dynamic Yield.
 * @param {string} dyId - Current Identifier of Dynamic Yield.
 * @param {string} email - The user's email.
 * @returns {Promise<any>} - A promise that resolves with the response from the Dynamic Yield API.
 */
export const dynamicYieldTrackingFetcher = async (
  dyType: string,
  dyId: string,
  dySession: string,
  email: string,
): Promise<any> => {
  const hashedEmail = await hashData(email);

  const fetchUri = config.dynamicYieldTracking.endpointClientSide
    .replace('<dyType>', dyType)
    .replace('<dyId>', dyId)
    .replace('<dySession>', dySession)
    .replace('<hashedEmail>', hashedEmail);

  const res = await fetch(fetchUri);

  if (!res.ok) {
    throw new Error(`Endpoint: ${res.url} - Status ${res.status}: ${res.statusText}`);
  }

  return res.json();
};
