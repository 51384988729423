/**
 * Function to encode/decode a string to/from ROT47
 * @param text String to be encoded/decoded
 * @returns Encoded/decoded string
 */
export function rot47(text: string) {
  return text
    .split('')
    .map((char) => {
      const charCode = char.charCodeAt(0);
      const newCharCode =
        charCode >= 33 && charCode <= 126 ? 33 + ((charCode + 14) % 94) : charCode;

      return String.fromCharCode(newCharCode);
    })
    .join('');
}
