import { Box, Dialog } from '@packages/shared';
import type { ModalProps } from './types';
import { ModalTestIds } from './types';

/** Composite dialog component with title and content that is initialized by the CMS application. Based on Shared Dialog: @packages/shared/Dialog */
export const Modal = ({ openState: { isOpen, setIsOpen }, title, children }: ModalProps) => {
  const handleClose = () => setIsOpen(false);

  return (
    <Dialog
      open={isOpen}
      handleClose={handleClose}
      title={title}
      dialogContent={
        <Box
          data-testid={ModalTestIds.content}
          sx={{ padding: '1rem', overflowY: 'auto', maxHeight: '85vh' }}
        >
          {children}
        </Box>
      }
    />
  );
};
