import {
  FormControl,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
} from '@mui/material';
import { useTracking, type GTMEventGlycerinButtonClick } from '@packages/tracking';

/** Based on MUI Toggle Button: https://mui.com/material-ui/react-toggle-button/ */
export const ToggleButtonGroup = ({
  value,
  handleChange,
  options,
  label,
  id,
}: {
  value: string;
  handleChange: (event: React.MouseEvent<HTMLElement>, value: string) => void;
  options: {
    value: string;
    label: string;
    disabled?: boolean;
  }[];
  id: string;
  label?: string;
}) => {
  const dispatchGTMEvent = useTracking();
  return (
    <FormControl>
      {label && (
        <FormLabel
          id={`${id}-controlled-toggle-buttons-group`}
          sx={{ typography: 'body2', color: 'text.dark' }}
        >
          {label}
        </FormLabel>
      )}
      <MuiToggleButtonGroup
        aria-labelledby={`${id}-controlled-toggle-buttons-group`}
        value={value}
        exclusive
        onChange={handleChange}
      >
        {options.map((option) => (
          <ToggleButton
            onClick={() =>
              dispatchGTMEvent<GTMEventGlycerinButtonClick>({
                event: 'ButtonClick',
                ButtonClickData: {
                  category: 'toggle-button-click',
                  target: '',
                  label: option.label,
                  detail: option.value,
                },
              })
            }
            value={option.value}
            key={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </ToggleButton>
        ))}
      </MuiToggleButtonGroup>
    </FormControl>
  );
};

ToggleButtonGroup.displayName = 'ToggleButtonGroup';
