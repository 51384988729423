import { useEffect, useId, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Card,
  CardContent,
  Stack,
  TextField,
  ToggleButtonGroup,
  Button,
  useDeviceType,
  Link,
  useI18n,
  LinkButton,
} from '@packages/shared';
import { useConfig, useCookies, useCustomer } from '@packages/utilities';
import type { GTMEventGlycerinDisplayForm } from '@packages/tracking';
import { useTracking } from '@packages/tracking';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from '../../Modal';
import { formMessages, generalFormMessages, salutationSelectMessages } from '../messages';
import { ActivityOverlay } from '../ActivityOverlay';
import { SuccessPage } from '../SuccessPage';
import { basicContainer, smallBasicContainer, stylePanel } from '../styles';
import {
  pagesMessages,
  privacyInfoMessages,
  subscribeMessages,
  voucherModalMessages,
} from './helpers/messages';
import IconNlSubscribe1Default from './animated/IconNlSubscribe1Default';
import type { FormValues, Page1FormProps } from './types';
import { InputIDs } from '../types';
import { Page1FormModalContent } from './Page1FormModalContent';
import { useSubmitNlSubscribeRest } from './helpers/useSubmitNlSubscribeRest';
import { useSubmitNlSubscribeGql } from './helpers/useSubmitNlSubscribeGql';
import { getFieldProps } from './helpers/getFieldProps';
import { getDefaultValues, newsletterSubscribeFormSchema } from './helpers/settings';

export const Page1Form = ({
  areaKey,
  setPage,
  headline,
  text,
  conditionsLabel,
  conditionsText,
  buttonLabel,
  buttonLabelMobile,
}: Page1FormProps) => {
  const intl = useIntl();
  const uniqueId = useId();
  const { formatMessage } = intl;
  const { customer } = useCustomer();
  const dispatchGTMEvent = useTracking();
  const { isMobile } = useDeviceType();
  const { language } = useI18n();

  const [modalOpen, setModalOpen] = useState(false);
  const schema = newsletterSubscribeFormSchema(intl);
  const { register, formState, handleSubmit, control, setValue, setError } = useForm<FormValues>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(customer),
  });
  const {
    forms: { useOptimizely, newsletterSubscribe },
  } = useConfig();

  const { errors, isValid, isSubmitting } = formState;
  const { getCookies } = useCookies();
  const doSubmitNlSubscribeGql = useSubmitNlSubscribeGql(areaKey, setPage);
  const doSubmitNlSubscribeRest = useSubmitNlSubscribeRest(setError, areaKey, setPage);
  const submitForm = (formData: FormValues) =>
    getCookies()['inspire.optimizely'] === 'true' || useOptimizely
      ? doSubmitNlSubscribeGql(formData)
      : doSubmitNlSubscribeRest(formData);

  useEffect(
    () => {
      dispatchGTMEvent<GTMEventGlycerinDisplayForm>({
        event: 'DisplayForm',
        DisplayFormData: {
          category: 'newsletter-subscribe',
          label: 'registration',
        },
      });
    },
    // INSPIRE-3537 - only fire once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const customButtonLabel = isMobile ? buttonLabelMobile : buttonLabel;
  const submitButtonLabel = isValid
    ? customButtonLabel || formatMessage(subscribeMessages.submit)
    : formatMessage(subscribeMessages.submitDisabled);
  const unsubscribeLink = newsletterSubscribe[language]?.unsubscribeLink;
  const getFieldPropsFor = getFieldProps(register, errors, formatMessage, schema);

  return (
    <ActivityOverlay showLoader={isSubmitting}>
      <Modal
        title={conditionsLabel || formatMessage(voucherModalMessages.title)}
        openState={{ isOpen: modalOpen, setIsOpen: setModalOpen }}
      >
        <Page1FormModalContent conditionsText={conditionsText} />
      </Modal>

      <SuccessPage
        icon={<IconNlSubscribe1Default sx={{ fontSize: '8rem', mb: 3 }} />}
        headline={headline || formatMessage(pagesMessages.page1.headline)}
        description={text || formatMessage(pagesMessages.page1.description)}
      />
      <form onSubmit={handleSubmit(submitForm)} noValidate name="page1form">
        <Box sx={stylePanel}>
          <Card sx={smallBasicContainer}>
            <CardContent sx={{ padding: 4 }}>
              <Stack spacing={4}>
                <Controller
                  control={control}
                  name="salutation"
                  render={({ field }) => (
                    <ToggleButtonGroup
                      id={InputIDs.SALUTATION}
                      label={formatMessage(formMessages[InputIDs.SALUTATION].label)}
                      options={(['female', 'male', 'notSpecified'] as const).map((value) => ({
                        value,
                        label: formatMessage(salutationSelectMessages[value]),
                      }))}
                      value={field.value || ''}
                      handleChange={(_e, value) => setValue(field.name, value as never)}
                    />
                  )}
                />
                <TextField {...getFieldPropsFor(InputIDs.FIRSTNAME, uniqueId)} />
                <TextField {...getFieldPropsFor(InputIDs.LASTNAME, uniqueId)} />
                <TextField
                  {...getFieldPropsFor(InputIDs.EMAIL, uniqueId)}
                  placeholder={formatMessage(formMessages[InputIDs.EMAIL].placeholder)}
                  type="email"
                  required
                />
                <Box sx={{ typography: 'body2', a: { color: 'text.dark' } }}>
                  <FormattedMessage
                    id={subscribeMessages.confirmation.id}
                    defaultMessage={subscribeMessages.confirmation.defaultMessage}
                    values={{
                      a: (chunks) => <a href={unsubscribeLink}>{chunks}</a>,
                    }}
                  />
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Box>

        <Box
          sx={{
            ...smallBasicContainer,
            marginTop: 1,
            marginBottom: 5,
            typography: 'body2',
            color: 'text.darkTransparent',
          }}
        >
          {formatMessage(generalFormMessages.mandatory)}
        </Box>

        <Box sx={{ ...basicContainer, textAlign: 'center', marginTop: 2 }}>
          <Button
            disabled={!isValid}
            size="large"
            fullWidth={isMobile}
            color="primary"
            type={isValid ? 'submit' : 'button'}
            clickTrackingProps={{
              category: 'newsletter-subscribe',
              label: 'registration',
              detail: 'send',
              custom: { areaKey },
            }}
          >
            {submitButtonLabel}
          </Button>

          {/* Link to open modal with voucher conditions */}
          <LinkButton
            variant="body3"
            sx={{
              mt: 3.5,
              mb: 2,
              display: 'block',
              mx: 'auto',
            }}
            onClick={() => setModalOpen(true)}
          >
            {conditionsLabel || formatMessage(voucherModalMessages.linkText)}
          </LinkButton>

          {/* Link to open privacy information */}
          <Link
            href={formatMessage(privacyInfoMessages.link)}
            color="inherit"
            sx={{ typography: 'body3' }}
          >
            {formatMessage(privacyInfoMessages.text)}
          </Link>
        </Box>
      </form>
    </ActivityOverlay>
  );
};
