/**
 * Hashes the provided data using SHA-256.
 *
 * doc https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
 *
 * @param {string} data - The data to be hashed.
 * @returns {Promise<string>} - A promise that resolves with the hashed data.
 */
export const hashData = async (data: string): Promise<string> => {
  // convert the E-Mail-String to ArrayBuffer
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(data);

  // encrypt string to SHA-256 Hash by using Web Crypto API
  const hash = await crypto.subtle.digest('SHA-256', encodedData);

  // convert Hash to Hex-String
  return Array.from(new Uint8Array(hash))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
};
