import { Box, Button, Link, Typography } from '@packages/shared';
import { Checkmark32 } from '@packages/themes/icons';
import { styleContentTop } from './styles';

export type SuccessPageProps = {
  /** The Icon at the top. If unset, the green Tick is used */
  icon?: JSX.Element;
  /** Main Headline */
  headline: string;
  /** Text under the headline */
  description: string;
  /** Button that links to Home Page */
  button?: {
    /** Button text */
    text: string;
    /** If true, the button is colorful and can be used as a CTA */
    primary?: boolean;
  };
  /** less important Text at the bottom */
  subtext?: string;
  sx?: {
    maxWidth: string;
    m: string;
  };
};

/**
 * Renders a Success Icon with headline and description to notify about
 * a successfully submitted form.
 */
export const SuccessPage = ({
  icon,
  headline,
  description,
  button,
  subtext,
  sx,
}: SuccessPageProps) => (
  <Box sx={{ ...styleContentTop, ...sx }}>
    <Box sx={{ marginBottom: 1 }}>
      {icon || <Checkmark32 sx={{ color: 'success.main', fontSize: '5rem' }} />}
    </Box>
    <Typography variant="h3" component="p" sx={{ marginBottom: 2.25 }}>
      {headline}
    </Typography>
    <Typography>{description}</Typography>
    {button && (
      <Button
        LinkComponent={Link}
        href="/"
        color={button.primary ? 'primary' : 'inherit'}
        sx={{ marginTop: button.primary ? 4 : 7 }}
      >
        {button.text}
      </Button>
    )}
    {subtext && (
      <Box
        sx={{ paddingTop: 2.5, typography: 'body2', color: 'text.darkTransparent' }}
        component="p"
      >
        {subtext}
      </Box>
    )}
  </Box>
);
