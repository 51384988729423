import * as yup from 'yup';
import type { CheckboxID, InputID } from '../types';
import { Errors, InputIDs, CheckboxIDs } from '../types';

/** Numbers only */
export const numbersOnly = /^[0-9]*$/;

/** No special charaters and no numbers allowed */
export const noSpecCharsNoNumbers = /^[A-Za-z.\-ßÄÖÜäöü ]*$/;

/** No special charaters allowed */
export const noSpecChars = /^[A-Za-z0-9.\-ßÄÖÜäöü ]*$/;

const generalFormSchema = {
  [InputIDs.FIRSTNAME]: yup
    .string()
    .matches(noSpecCharsNoNumbers, Errors.NOSPECCHARSNONUMBERS)
    .required(Errors.REQUIRED),
  [InputIDs.LASTNAME]: yup
    .string()
    .matches(noSpecCharsNoNumbers, Errors.NOSPECCHARSNONUMBERS)
    .required(Errors.REQUIRED),
  [InputIDs.STREET]: yup
    .string()
    .matches(noSpecCharsNoNumbers, Errors.NOSPECCHARSNONUMBERS)
    .required(Errors.REQUIRED),
  [InputIDs.HOUSENUMBER]: yup
    .string()
    .matches(noSpecChars, Errors.NOSPECCHARS)
    .required(Errors.REQUIRED),
  [InputIDs.POSTALCODE]: yup
    .string()
    .matches(numbersOnly, Errors.NUMBERSONLY)
    .required(Errors.REQUIRED),
  [InputIDs.CITY]: yup
    .string()
    .matches(noSpecCharsNoNumbers, Errors.NOSPECCHARSNONUMBERS)
    .required(Errors.REQUIRED),
  [InputIDs.EMAIL]: yup.string().email(Errors.EMAIL).required(Errors.REQUIRED),
  [InputIDs.MESSAGE]: yup.string().required(Errors.REQUIRED),
  [InputIDs.PHONENUMBER]: yup.string(),
  [InputIDs.CUSTOMERID]: yup.string().matches(noSpecChars, Errors.NOSPECCHARS),
  [InputIDs.SUBJECT]: yup.string(),
};

const revocationSchema = {
  ...generalFormSchema,
  [CheckboxIDs.CANCELADVERTISING]: yup.boolean(),
  [CheckboxIDs.CANCELMARKETING]: yup.boolean(),
  [CheckboxIDs.CANCELNL]: yup.boolean(),
};

const rateProtectionSchema = {
  ...generalFormSchema,
  [InputIDs.CUSTOMERID]: yup
    .string()
    .matches(noSpecChars, Errors.NOSPECCHARS)
    .required(Errors.REQUIRED),
  [InputIDs.MESSAGE]: yup.string(),
  [InputIDs.BIRTHDAY]: yup.date().typeError(Errors.INVALIDDATE),
  [CheckboxIDs.CANCELADVERTISING]: yup.boolean(),
  [CheckboxIDs.CANCELMARKETING]: yup.boolean(),
  [CheckboxIDs.CANCELNL]: yup.boolean(),
  [CheckboxIDs.TERMS]: yup.boolean().required().isTrue(Errors.REQUIRED),
};

const newsletterSubscribeSchema = {
  [InputIDs.EMAIL]: yup.string().required(Errors.REQUIRED).email(Errors.EMAIL),
};

const newsletterUnsubscribeSchema = {
  [InputIDs.EMAIL]: yup.string().required(Errors.REQUIRED).email(Errors.EMAIL),
  [InputIDs.FREQUENCY]: yup.string().required(Errors.REQUIRED),
  [InputIDs.PAUSE]: yup.string(),
};

export const contactFormSchema = yup.object(generalFormSchema);
export const revocationFormSchema = yup.object(revocationSchema);
export const rateProtectionFormSchema = yup.object(rateProtectionSchema);

export const newsletterSubscribeFormSchema = yup.object(newsletterSubscribeSchema);
export const newsletterUnsubscribeFormSchema = yup.object(newsletterUnsubscribeSchema);

export type SchemaType = yup.AnyObject & { [field in InputID]?: yup.AnySchema } & {
  [field in CheckboxID]?: yup.AnySchema;
};
